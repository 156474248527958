
import { defineComponent, PropType } from 'vue';
import tempKeysAdd from './temp-keys-add.vue';
import deliveryStaffAddOrEdit from './delivery-staff-add-or-edit.vue';

export default defineComponent({
    components: {
        tempKeysAdd,
        deliveryStaffAddOrEdit
    },
    props: {
        type: {
            type: String,
            required: true
        },
        option: {
            type: String as PropType<'add' | 'edit'>,
            default: 'add'
        },
        optionUUID: {
            type: String,
            default: ''
        }
    },
    setup() {
        return {};
    }
});
