import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_temp_keys_add = _resolveComponent("temp-keys-add")
  const _component_delivery_staff_add_or_edit = _resolveComponent("delivery-staff-add-or-edit")

  return (_openBlock(), _createBlock("div", null, [
    (_ctx.type==='tempKey')
      ? (_openBlock(), _createBlock(_component_temp_keys_add, { key: 0 }))
      : (_openBlock(), _createBlock(_component_delivery_staff_add_or_edit, {
          key: 1,
          type: _ctx.type,
          option: _ctx.option,
          optionUUID: _ctx.optionUUID
        }, null, 8, ["type", "option", "optionUUID"]))
  ]))
}