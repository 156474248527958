import { Ref, ref } from 'vue';
import { office } from '@/data';

interface Option {
    key: string;
    value: string;
}
interface DepartmentOption {
    ID: string;
    UnitName: string;
}
interface PeopleOption {
    ID: string;
    Name: string;
    UnitID: string;
}
const init = () => {
    const departmentOptions: Ref<Array<Option>> = ref([]);
    const peopleOptions: Ref<Array<Option>> = ref([]);

    let allDepartment: Array<DepartmentOption> = [];
    let allPeople: Array<PeopleOption> = [];

    // 保存部门、人员选项
    const setDepartmentOption = () => {
        departmentOptions.value = [];
        allDepartment.forEach((item) => {
            departmentOptions.value.push({
                key: item.ID,
                value: item.UnitName
            });
        });
    };
    const setPeopleOption = (departmentKey = '') => {
        peopleOptions.value = [];
        allPeople.forEach((item) => {
            if (departmentKey === '' || item.UnitID === departmentKey) {
                peopleOptions.value.push({
                    key: item.ID,
                    value: item.Name
                });
            }
        });
    };

    office.getDepartmentPeople((res: {
        data: {
            department: Array<DepartmentOption>;
            people: Array<PeopleOption>;
        };
    }) => {
        allDepartment = res.data.department;
        allPeople = res.data.people;
        setDepartmentOption();
        setPeopleOption();
    });

    return {
        departmentOptions,
        peopleOptions,
        setPeopleOption
    };
};

export default init;