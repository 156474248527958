import { ref } from 'vue';
import { byteLength } from 'byte-length';
import { ListHeaderItem } from '@/components/common/list';
import { access } from '@/data';
import { user, access as accessRules } from '@/methods/rule';
import { compareTime } from '@/methods/date';
import HttpRequest from '@/util/axios.config';
import { RepeatsRulesData } from './all.type';
import { checkIDNumber } from '@/methods/rules/access';

const checkTempKeyName: RuleMethod = (rule, value, callback) => {
    if (byteLength(value as string) > 63) {
        return callback(
            new Error(
                WordList.RuleLengthExceeds63.format(
                    WordList.TabelPersonUserInHtmlName,
                    WordList.TabelPersonUserInHtmlName
                )
            )
        );
    }
    return callback();
};

const getFormRules = (type: string) => {
    if (type === access.neverEachCounts) {
        return {
            Description: [{
                required: true,
                message: WordList.RuleNameEmpty,
                trigger: 'blur'
            }, {
                validator: checkTempKeyName,
                trigger: 'blur'
            }],
            BeginTime: [{
                type: 'date',
                required: true,
                message: WordList.ProperAllTextStartTimeRequired,
                trigger: 'change'
            }],
            ExpireTime: [{
                type: 'date',
                required: true,
                message: WordList.ProperAllTextEndTimeRequired,
                trigger: 'change'
            }],
            IDNumber: [{
                validator: checkIDNumber,
                trigger: 'blur'
            }],
            Allow: [{
                required: true,
                message: WordList.RuleAllowCountEmpty,
                trigger: 'blur'
            }, {
                validator: accessRules.checkAllowedTimes,
                trigger: 'blur'
            }],
            Delivery: [{
                validator: user.checkEmail,
                trigger: 'blur'
            }]
        };
    }
    // daily
    const rules: RepeatsRulesData = {
        Description: [{
            required: true,
            message: WordList.RuleNameEmpty,
            trigger: 'blur'
        }, {
            validator: checkTempKeyName,
            trigger: 'blur'
        }],
        StartTime: [{
            required: true,
            message: WordList.ProperAllTextStartTimeRequired,
            trigger: 'blur'
        }],
        StopTime: [{
            required: true,
            message: WordList.ProperAllTextEndTimeRequired,
            trigger: 'blur'
        }],
        IDNumber: [{
            validator: checkIDNumber,
            trigger: 'blur'
        }],
        Delivery: [{
            validator: user.checkEmail,
            trigger: 'blur'
        }]
    };
    if (type === access.weekly) {
        rules.DateFlag = [
            {
                required: true,
                message: WordList.ProperAllTextDayRequired,
                trigger: 'blur'
            }
        ];
    }
    return rules;
};

const getDepartmentPeople = (DepartmentID: string) => {
    HttpRequest.get('v3/web/office/user/getOfficeUserList', {
        DepartmentID
    }, (res: {
        data: {
            detail: Array< object >;
        };
    }) => {
        //
        console.log(res);
    });
};

type ProjectData = {
    [key in ProjectType]: {
        header: Array<ListHeaderItem>;
    }
}
const getTempData = () => {
    const communityHeader: Array<ListHeaderItem> = [{
        name: 'UnitName',
        label: WordList.DeviceDetailDetailUnitName
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'Relay',
        label: WordList.ProperAllTextRelay,
        headerType: 'customize',
        type: 'customize'
    }];
    const officeHeader: Array<ListHeaderItem> = [{
        name: 'UnitName',
        label: WordList.ProperAllTextDepartment
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'Relay',
        label: WordList.ProperAllTextRelay,
        headerType: 'customize',
        type: 'customize'
    }];
    const projectData: ProjectData = {
        community: {
            header: communityHeader
        },
        office: {
            header: officeHeader
        }
    };
    return projectData;
};

// 检查开始、结束时间范围是否符合
const checkTimeRange = () => {
    const isShowTimeRangeTips = ref(false);
    const checkRange = (startTime: string | Date, endTime: string | Date) => {
        if (!compareTime(startTime, endTime)) {
            isShowTimeRangeTips.value = true;
        } else {
            isShowTimeRangeTips.value = false;
        }
        return !isShowTimeRangeTips.value;
    };
    return {
        isShowTimeRangeTips,
        checkRange
    };
};

export default null;
export {
    getFormRules,
    getDepartmentPeople,
    getTempData,
    checkTimeRange
};
