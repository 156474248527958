
import {
    computed,
    defineComponent, ref, Ref, watch
} from 'vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import { propertyBaseRouter, propertySubRouter } from '@/router/routes/property';
import { access, community } from '@/data';
import {
    list, ListHeaderItem, RequestData
} from '@/components/common/list';
import initCheck from '@/methods/relay-choose';
import Notice from '@/util/notice.base';
import { format } from '@/methods/date';
import HttpRequest from '@/util/axios.config';
import router from '@/router';
import init from '@/methods/building-apt-choose';
import officeInit from '@/methods/department-people-choose';
import { ChangeWordList } from '@/methods/setText';
import { BreadCrumbData, TempKeyFormData } from './all.type';
import { getFormRules, getTempData, checkTimeRange } from './util';

export default defineComponent({
    components: {
        propertyBreadCrumb,
        list
    },
    setup() {
        const projectType = community.ProjectType;
        const headers: Ref<Array<ListHeaderItem>> = ref([]);
        watch(projectType, () => {
            const tempData = getTempData()[projectType.value];
            headers.value = tempData.header;
        }, {
            immediate: true
        });
        // 面包屑
        const breadCrumb: Ref<Array<BreadCrumbData>> = ref([
            {
                label: WordList.DarshbordTmp,
                path: `/${propertyBaseRouter}/${propertySubRouter.personnel}?type=tempKey`
            }, {
                label: WordList.ProperAllTextNew
            }
        ]);

        // form相关操作
        const formRef: Ref<any> = ref();
        const formData: Ref<TempKeyFormData> = ref({
            Build: '',
            Room: '',
            Description: '',
            ExpireTime: '',
            StartTime: '',
            StopTime: '',
            Allow: '',
            MAC: '',
            IDNumber: '',
            Delivery: '',
            SchedulerType: '3',
            DateFlag: [],
            OfficeUserID: '',
            BeginTime: new Date()
        });

        const rules = ref(getFormRules(formData.value.SchedulerType));
        watch(computed(() => formData.value.SchedulerType), () => {
            rules.value = getFormRules(formData.value.SchedulerType);
        });

        const updateToList = ref(0);
        const listRequestData: Ref<RequestData> = ref({
            url: `v3/web/${community.ProjectType.value}/device/getAllPubDoor`,
            param: {
                Building: computed(() => formData.value.Build)
            }
        });

        const { buildOptions, aptOptions, setRoomOption } = init('all', 'aptAndName');
        const { departmentOptions, peopleOptions, setPeopleOption } = officeInit();
        watch(computed(() => formData.value.Build), (val) => {
            if (projectType.value === 'community') {
                setRoomOption(val === 'all' ? '' : val as string);
                formData.value.Room = '';
            } else {
                setPeopleOption(val === 'all' ? '' : val as string);
                formData.value.OfficeUserID = '';
            }
            updateToList.value += 1;
            // formData.value.Room = '';  旧版本没清，但是如果不清提交后会有填写build填写1 选完room没更新build
        }, { deep: true });

        // 6.4 modify by lxf, never类型为3, 兼容each door counts
        const repeatsOption = [{
            label: WordList.ProperAllTextNever,
            value: '3'
        }, {
            label: WordList.ProperAllTextDaily,
            value: '1'
        }, {
            label: WordList.ProperAllTextWeekly,
            value: '2'
        }];

        const {
            allCheck, checkArray, dealList, choose, getCommit, changeAllCheck
        } = initCheck();

        const {
            isShowTimeRangeTips,
            checkRange
        } = checkTimeRange();

        const submit = () => {
            const devices = getCommit();
            if (devices.length === 0) {
                Notice.messageBox('alert', WordList.RuleMacChoose, WordList.PersonuserInfo, 'info')(() => false);
                return false;
            }
            formRef.value.validate((valid: boolean) => {
                const param = { ...formData.value };
                switch (param.SchedulerType) {
                    case access.neverEachCounts:
                        param.DateFlag = '';
                        break;
                    case access.daily:
                        param.Allow = '';
                        param.DateFlag = '';
                        break;
                    case access.weekly:
                        param.Allow = '';
                        break;
                    default:
                        break;
                }
                param.BeginTime = format(param.BeginTime, 'YYYY-MM-DD HH:mm:ss');
                param.ExpireTime = format(param.ExpireTime, 'YYYY-MM-DD HH:mm:ss');
                param.StartTime = format(param.StartTime, 'HH:mm:ss');
                param.StopTime = format(param.StopTime, 'HH:mm:ss');
                if (valid) {
                    if (param.SchedulerType === access.neverEachCounts && !checkRange(param.BeginTime, param.ExpireTime)) return;
                    if (typeof param.DateFlag !== 'string') param.DateFlag = param.DateFlag.join(';');
                    HttpRequest.post(`v3/web/${community.ProjectType.value}/tempKey/addPubTempKey`, { ...param, MAC: devices }, () => {
                        router.push(`/${propertyBaseRouter}/${propertySubRouter.personnel}`);
                    });
                }
            });

            return true;
        };

        return {
            breadCrumb,
            formData,
            formRef,
            rules,
            repeatsOption,
            access,
            headers,
            updateToList,
            listRequestData,
            allCheck,
            checkArray,
            dealList,
            choose,
            submit,
            buildOptions,
            aptOptions,
            changeAllCheck,
            ChangeWordList,
            departmentOptions,
            peopleOptions,
            projectType,
            isShowTimeRangeTips
        };
    }
});
